<template>
  <div class="container my-4 my-lg-5">
    <Detail/>
  </div>
</template>

<script>
import Detail from '@/components/publication/Detail.vue'

export default {
  name: 'Publication',
  data: function () {
    return {
      lang: this.$route.query.lang == 'en' ? 'en' : 'ja'
    }
  },
  components: {
    Detail
  }
}
</script>