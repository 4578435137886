<template>
<div class="row justify-content-center">
  <div class="col-10 order-md-2 mb-5">
    <div class="row justify-content-center contact">
      <div class="col-12 py-3" v-if="lang === 'en'">
        <h3><i class="fas fa-map-marker-alt icon"></i>Address</h3>
        <div class="row">
          <div class="col-lg-auto text-lg-center">
            <p ><span>55N406</span><span>3-4-1,</span> Okubo, Shinhuku-ku, Tokyo, 169-0072, Japan</p>
          </div>
        </div>
        <hr class="d-lg-none">
        <div class="row">
          <div class="col-lg-auto text-lg-te">
            <p ><span>121-507</span><span>Research & Development Center of Waseda University</span> 513-2, Tsurumaki-cho, Shinjuku-ku, Tokyo, 162-0041, Japan </p>
          </div>
        </div>
      </div>
      <div class="col-12 py-3" v-else >
        <h3><i class="fas fa-map-marker-alt icon"></i>Address</h3>
        <div class="row">
          <div class="col-lg-auto offset-lg-1 offset-xl-2">
            <p>〒169-0072</p>
          </div>
          <div class="col-lg-auto text-lg-left">
            <p><span>東京都新宿区</span><span>大久保3-4-1</span> <span>55N406</span></p>
          </div>
        </div>
        <hr class="d-lg-none">
        <div class="row">
          <div class="col-lg-auto offset-lg-1 offset-xl-2">
            <p>〒162-0041</p>
          </div>
          <div class="col-lg-auto text-lg-left">
            <p><span></span><span>早稲田鶴巻町513-2</span> <span>早稲田大学研究開発センター</span> <span>121-507</span></p>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-sm-6 col-lg-4 py-3">
        <h3><i class="fas fa-phone-alt icon"></i>Phone</h3>
        <p>+81-3-5286-3510</p>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 py-3">
        <h3><i class="fas fa-envelope icon"></i>Email</h3>
        <p>info <i class="fas fa-at"></i> mlab.phys.waseda.ac.jp</p>
      </div>
      <div class="col-12 col-sm-auto col-lg-4 py-3">
        <h3><i class="fas fa-home icon"></i>{{ lang == 'en' ? "Old web page" : "旧Webページ" }}</h3>
        <p><a href="http://www.mlab.phys.waseda.ac.jp/">http://www.mlab.phys.waseda.ac.jp/</a></p>
      </div>

    </div>
  </div>
  <div class="col-12 order-md-1 my-3">
    <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1528.966872814801!2d139.72062491019852!3d35.707325405030005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d229c4c67f5%3A0x654d4739a95a0689!2z5pep56iy55Sw5aSn5a2m56CU56m26ZaL55m644K744Oz44K_44O8IDEyMeWPt-mkqA!5e0!3m2!1sja!2sjp!4v1615559788356!5m2!1sja!2sjp" allowfullscreen="" loading="lazy"></iframe>
  </div>
</div>
</template>

<script>
export default {
  name: 'ContactInfo',
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style scoped>
.map
{
  width:100%;
  height:50vh;
  border:0;
}

.contact > div
{
  background-color: #f9f9f9;
  border: 2px solid white
}

span
{
  white-space: nowrap;
  display: inline-block;
}

</style>