<template>
<div class="row justify-content-center collaborator">
  <div class="col-10 col-sm-6 col-lg-4 py-3" v-for="collaborator in this.collaborators" :key="collaborator.id">
    <h4><span>{{ lang == 'en' ? collaborator.eng : collaborator.name }}</span> <span>{{ lang == 'en' ? collaborator.status_en : collaborator.status }}</span></h4>
    <h5>{{ lang == 'en' ? collaborator.affliation_en : collaborator.affiliation }}</h5>
    <a :href="collaborator.url">Page <i class="fas fa-external-link-alt"></i></a>
  </div>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Collaborators',
  data: function () {
    return {
      db: null,
      collaborators: []
    }
  },
  created: function () {
    this.db = firebase.firestore()

    var _this = this
    this.db.collection('collaborators').orderBy("index").onSnapshot(function (querySnapshot) {
      _this.collaborators = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        _this.collaborators.push(data)
      })
    })
  },
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style>
.collaborator > div
{
  background-color: #f9f9f9;
  border: 2px solid white
}
span
{
  white-space: nowrap;
  display: inline-block;
}

.collaborator .fa-external-link-alt
{
  font-size: 0.8em;
}
</style>