<template>
  <div class="publication vue-infinite-loading">
    <article class="row mx-0" v-for="publication in this.publications" :key="publication.id">
      <div class="col-md-2 col-lg-1 date-col">
        <p class="date"> {{ publication.year }}<span v-if="publication.month">.{{publication.month}}<span v-if="publication.day">.{{publication.day}} </span></span></p>
        <hr class="d-md-none md-date-line">
      </div>
      <div class="col-md-3 justify-content-center thumbnail d-flex">
        <div class="my-auto">
          <img class="img-fluid" v-if="publication.thumbnail_url === ''" src="http://drive.google.com/uc?export=view&id=1vBXi5cw051yaGFVURGGZYtU0oC6JN3EP+'#dummy.png'">
          <img class="img-fluid" v-else v-bind:src="'https://drive.google.com/thumbnail?id='+publication.thumbnail_url.split('=')[1]+'&sz=w500#dummy.png'">
        </div>
      </div>
      <div class="col-md-7 col-lg-8 article-summary">
        <h2 class="paper-title"><a v-bind:href="`/publication/${publication.key}`"> {{ publication.title }} </a></h2>
        <h3 class="authors"> {{ publication.authors }} </h3>
        <div v-if="publication.type==='ジャーナル'">
          <h4 class="publication-info"> {{ publication.publication_name }}<span v-if="publication.vol">, Vol. {{ publication.vol }}</span><span v-if="publication.no">, No. {{ publication.no }}</span><span v-if="publication.page">, {{ publication.page }}</span></h4>
          <h4 class="publication-doi"> <span v-if="publication.doi">DOI: <a :href="publication.doi"> {{ publication.doi }} </a></span></h4>
        </div>
        <div v-else-if="(publication.type==='国際会議論文(査読有)' || publication.type==='国際会議論文(査読無)' || publication.type==='国内会議論文(査読有)' || publication.type==='国内会議論文(査読無)')">
          <h4 class="publication-info"> {{ publication.conference_name }} </h4>
          <h4 class="publication-doi"> <span v-if="publication.doi">DOI: <a :href="publication.doi"> {{ publication.doi }} </a> </span></h4>
        </div>
        <div v-else-if="publication.type==='受賞'">
          <h4 class="publication-info"> {{ publication.award }} </h4>
        </div>
        <p class="text-justify abstract"> {{ publication.abstract }} 
          <a class="detail" :href="`/publication/${publication.key}`"> <i class="fas fa-angle-double-right"></i> more </a>
        </p>

        <div class="link">
          <a class="btn btn-outline-info" v-if="publication.paper_url" :href="publication.paper_url" target="_blank" rel="noopener noreferrer">Paper</a>
          <a class="btn btn-outline-info" v-if="publication.bibtex_url" :href="publication.bibtex_url">Bibtex</a>
          <a class="btn btn-outline-info" v-if="publication.google_scholar_url" :href="publication.google_scholar_url" target="_blank" rel="noopener noreferrer">Google Scholar</a>
          <a class="btn btn-outline-info" v-if="publication.youtube_url" :href="publication.youtube_url" target="_blank" rel="noopener noreferrer">Youtube</a>
          <a class="btn btn-outline-info" v-if="publication.code_url" :href="publication.code_url" target="_blank" rel="noopener noreferrer">Code</a>
          <a class="btn btn-outline-info" v-if="publication.project_page_url" :href="publication.project_page_url" target="_blank" rel="noopener noreferrer">Project Page</a>
        </div>
      </div>
    </article>
  </div>
  <infinite-loading v-if="first_load == true"  @infinite="infiniteHandler" spinner="spiral" direction="bottom">
    <slot name="no-result">fdf</slot>
  </infinite-loading>
  <infinite-loading v-if="first_load == false"  spinner="spiral" direction="bottom">
    <slot name="no-result">dfd</slot>
  </infinite-loading>
</template>

<script>
import firebase from 'firebase'
import InfiniteLoading from 'vue-infinite-loading'


export default {
  name: 'PublicationList',
  props: ['target'],
  data: function () {
    return {
      db: null,
      publications: [],
      all_publications: [],
      unset: null,
      num_load: 10,
      first_load: false,
    }
  },
  components: {
    InfiniteLoading,
  },
  watch: {
    target: function() {
      this.unset();
      var type_list = { '論文誌':['ジャーナル'], '国際会議論文':['国際会議論文(査読無)', '国際会議論文(査読有)'], '国内会議論文':['国内会議論文(査読有)'], 'シンポジウム・研究会':['国内会議論文(査読無)'], '受賞':['受賞'], '報道':['報道']}

      var _this = this
      _this.first_load = false
      _this.all_publications = []
      _this.publications = []

      this.db.collection('publications').where('type', 'in', type_list[_this.target]).orderBy('year', 'desc').orderBy('month', 'desc').orderBy('day', 'desc').onSnapshot(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data()
          _this.all_publications.push(data)
        })
        _this.first_load = true
        _this.publications = _this.all_publications.slice(0, _this.num_load)
      })  

    }
  },
  methods: {
    async infiniteHandler($state) {
      // 全部ロードし終わっていたら$state.complete(), まだあるなら $state.loaded()
      if (this.all_publications.length == this.publications.length) {
        $state.complete()
      } else {
        await this.fetchPublications()
        $state.loaded()
      }
    },
    async fetchPublications() {
      var _this = this
      var num_loaded = _this.publications.length
      let add_pubalications = _this.all_publications.slice(num_loaded, num_loaded+_this.num_load)
      _this.publications = [..._this.publications , ...add_pubalications]
    },
  },
  created: function () {
    this.db = firebase.firestore()
    var _this = this
    const query = this.db.collection('publications').where('type', 'in', ['ジャーナル', '国際会議論文(査読有)']).orderBy('year', 'desc').orderBy('month', 'desc').orderBy('day', 'desc');
    this.unset = query.onSnapshot(function (querySnapshot) {
      _this.all_publications = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        _this.all_publications.push(data)
      })
      _this.publications = _this.all_publications.slice(0, _this.num_load)
      _this.first_load = true
    })
  },
}
</script>

<style>
.link > a
{
  margin: 5px;
}

.publication .thumbnail .img-fluid
{
  max-height: 30vh;
}

.publication article
{
  position: relative;
}
@media screen and (max-width: 767px)
{
  .publication article
  {
    text-align: center;
    background-color: white;
    box-shadow: 0px 1px 3px 0px rgba(100,100,100,0.3);
    margin-bottom: 30px;
    padding: 45px 25px 30px 25px;
  }
}

.publication .date-col
{
  margin-top: 1rem;
  height: 100%;
}
@media screen and (max-width: 767px)
{
  .publication .date-col
  {
    border-bottom: none;
    padding-left: 15px;
  }
}
@media screen and (min-width: 768px)
{
  .publication .date-col
  {
    border-bottom: 1px solid #979797;
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px)
{
  .publication .md-date-line
  {
    display: block;
    max-width: 150px;
    margin: 25px auto;
    border-width: 1px;
    border-color: #979797;
  }
}

@media screen and (min-width: 768px)
{
  .publication .thumbnail 
  {
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      border-left: 1px solid #979797;
      border-bottom: 1px solid #979797;
  }
}
@media screen and (max-width: 767px)
{
  .publication .thumbnail 
  {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px)
{
  .publication .article-summary
  {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (min-width: 768px)
{
  .publication .article-summary
  {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #979797;
  }
}

.publication .article-summary
{
  text-align: left;
}

.publication .paper-title
{
  font-size: 1rem;
  font-weight: 700;
}

.publication .authors
{
  font-size: 1rem;
}

.publication .publication-info
{
  font-size: 1rem;
  font-weight: 700;
}

.publication .publication-doi
{
  font-size: 1rem;
}

.publication .publication-doi span
{
  display: block;
}

.publication .publication-doi a
{
  word-break: break-all;
}

.publication .abstract {
  --max-lines: 4;
  max-height: calc(1.5em * var(--max-lines));
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
}

.publication .abstract::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
  content: '\200C';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  text-align: center;
}

.publication .abstract .detail
{
  position: absolute;
  right: 5%;
  bottom: 0;
  text-align: right;
}
</style>