<template>
<div class="project">
  <article class="text-left my-3 my-lg-5" v-for="project in this.projects" :key="project.id">
    <div class="row">
      <div class="col-12">
        <h3 class="theme"> {{ project.theme }} </h3>
        <h3 class="title"><a :href="project.url"> {{ project.title }} </a></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="period"> {{ project.period }} </p>
        <p class="owner"> 研究代表者: {{ project.owner }} </p>
        <!-- <p class="text-justify description"> {{ temp }} </p> -->
        <p class="text-justify description"> {{ project.description }} </p>
        <p class="url"><a :href="project.url"> {{ project.url }} </a></p>
      </div>
    </div>
  <hr class="d-none d-md-block">
  </article>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Projects',
  data: function () {
    return {
      db: null,
      projects: [],
      // temp: "人間の音楽理解に焦点を当て、視聴覚情報を統合しながら、音響・映像データ中の楽譜情報やダンス動作を認識すると同時に、未知データの認識に再利用可能な音楽/身体感覚を自己獲得するためのモデルを研究する。従来独立して研究されてきた認識・生成タスクペアである、自動採譜・音楽合成、楽譜解析・自動作曲、ダンス動作解析・生成、ダンス映像解析・生成をシームレスに取り扱う統一システムを開発する。システム運用の検証を通じて、音楽理解の統計的定量化と構成論的解明を行う。"
    }
  },
  created: function () {
    this.db = firebase.firestore()

    var _this = this
    this.db.collection('projects').orderBy("index").onSnapshot(function (querySnapshot) {
      _this.projects = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        _this.projects.push(data)
      })
    })
  }
}
</script>

<style>
@media screen and (max-width: 767px)
{
  .project article
  {
    text-align: center;
    background-color: white;
    box-shadow: 0px 1px 3px 0px rgba(100,100,100,0.3);
    margin-bottom: 30px;
    padding: 45px 25px 30px 25px;
  }
}

.project .threme
{
  font-size: 2rem;
}

.project .title
{
  font-size: 1.5rem;
}
</style>