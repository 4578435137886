<template>
<article v-if="publication">
  <div class="row">
    <div class="col">
      <h1 class="title"> {{ publication.title }} </h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2 class="authors"> {{ publication.authors }} </h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <img class="img-fluid teaser" v-if="publication.image_url === ''" src="http://drive.google.com/uc?export=view&id=1vBXi5cw051yaGFVURGGZYtU0oC6JN3EP#dummy.png">
      <img class="img-fluid teaser" v-else v-bind:src="'https://drive.google.com/thumbnail?id='+publication.image_url.split('=')[1]+'&sz=w1000#dummy.png'">

    </div>
  </div>

  <div class="row">
    <div class="col">
      <div v-if="publication.type==='ジャーナル'">
        <h3 class="publication-info"> {{ publication.publication_name }}<span v-if="publication.vol">, Vol. {{ publication.vol }}</span><span v-if="publication.no">, No. {{ publication.no }}</span><span v-if="publication.page">, {{ publication.page }}</span></h3>
        <p class="publication-doi"> <span v-if="publication.doi">DOI: <a :href="publication.doi"> {{ publication.doi }} </a> </span></p>
      </div>
      <div v-else-if="(publication.type==='国際会議論文(査読有)' || publication.type==='国際会議論文(査読無)' || publication.type==='国内会議論文(査読有)' || publication.type==='国内会議論文(査読無)')">
        <h3 class="publication-info"> {{ publication.conference_name }} </h3>
        <p class="publication-doi"> <span v-if="publication.doi">DOI: <a :href="publication.doi"> {{ publication.doi }} </a> </span></p>
      </div>
      <div v-else-if="publication.type==='受賞'">
        <h3 class="publication-info"> {{ publication.award }} </h3>
      </div>
      <p class="date">Date : {{ publication.year }}.{{publication.month}}.{{publication.day}} </p>
    </div>
  </div>

  <div class="row justify-content-center abstract">
    <div class="col-lg-8">
      <h4>Abstract</h4>
      <p class="text-justify">{{ publication.abstract }}</p>
    </div>
    <div class="col-lg-4 extras" v-if="hasExtras">
      <h4>Extras</h4>
      <div class="row justify-content-center">
        <div class="col-4" v-if="publication.paper_url">
          <a class="paper" :href="publication.paper_url" target="_blank" rel="noopener noreferrer">
            <i class="far fa-file-pdf"></i>
            <p class="icon-caption">Paper</p>
          </a>
        </div>
        <div class="col-4" v-if="publication.bibtex_url">
          <a class="bibtex" :href="publication.bibtex_url" target="_blank" rel="noopener noreferrer">
            <div id="bib-icon">
              <i class="far fa-file"></i>
              <span id="bib-text">Bib</span>
            </div>
            <p class="icon-caption">BibTeX</p>
          </a>
        </div>
        <div class="col-4" v-if="publication.google_scholar_url">
          <a class="google-scholar" :href="publication.google_scholar_url" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-google"></i>
            <p class="icon-caption">Google Scholar</p>
          </a>
        </div>
        <div class="col-4" v-if="publication.youtube_url">
          <a class="youtube" :href="publication.youtube_url" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-youtube"></i>
            <p class="icon-caption">YouTube</p>
          </a>
        </div>
        <div class="col-4" v-if="publication.code_url">
          <a class="code" :href="publication.code_url" target="_blank" rel="noopener noreferrer">
            <i class="fas fa-code"></i>
            <p class="icon-caption">Code</p>
          </a>
        </div>
        <div class="col-4" v-if="publication.project_page_url">
          <a class="project-page" :href="publication.project_page_url" target="_blank" rel="noopener noreferrer">
            <i class="fas fa-globe"></i>
            <p class="icon-caption">Project</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</article>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'PublicationDetails',
  props: ['key'],
  data: function () {
    return {
      db: null,
      publication: null
    }
  },
  created: function () {
    this.db = firebase.firestore()

    var _this = this
    this.db.collection('publications').where('key', '==', _this.$route.params.key).onSnapshot(function (querySnapshot) {
      _this.publication = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        console.log(data)
        _this.publication = data
      })
    })
  },
  computed: {
    hasExtras: function () {
      return this.publication.paper_url || this.publication.bibtex_url || this.publication.google_scholar_url || this.publication.youtube_url || this.publication.code_url || this.publication.project_page_url
    }
  }
}
</script>

<style scoped>
.title
{
  text-align: center;
  margin: 1rem auto;
  font-size: 2rem;
}

.authors
{
  font-size: 1.4rem;
  margin: 1rem auto 2rem;
}

.teaser
{
  margin: 1rem auto;
}

.publication-info
{
  margin: 1rem auto;
}

#bib-icon
{
    position: relative;
}
#bib-text
{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-30%);
    -webkit-transform: translate(-50%,-30%);
    transform: translate(-50%,-30%);
    margin:0;
    padding:0;
    font-size: 1rem;
    font-weight: 700;
}

.extras svg
{
  font-size: 2rem;
}


</style>