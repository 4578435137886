<template>
<div class="container my-4 my-lg-5">
  <h1>Members</h1>
  <hr>
  <div class="row">
    <div class="col-12">
      <Biography/>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <Index/>
    </div>
  </div>
</div>
</template>

<script>
import Index from '@/components/member/Index.vue'
import Biography from '@/components/member/Biography.vue'

export default {
  name: 'Members',
  data: function () {
    return {
      lang: this.$route.query.lang == 'en' ? 'en' : 'ja'
    }
  },
  components: {
    Index,
    Biography
  }
}
</script>