<template>
<div class="biography">
    <div class="row justify-content-center align-items-center">
        <div class="col-lg-4 col-10">
            <div class="anim-wrap">
                <div class="trim-wrap">
                    <img class="img-fluid" src="../../assets/agenda_img.jpg"/>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-10 caption text-left">
            <div v-if="lang == 'en'">
                <h2 class="text-center">Shigeo Morishima</h2>
                <p>Dr. Shigeo Morishima was born in Kushimoto, Wakayama, Japan on August 20, 1959. He received the B.S., M.S. and Ph.D. degrees, all in Electrical Engineering from the University of Tokyo, Tokyo, Japan, in 1982, 1984, and 1987, respectively . From 1987 to 2001, he was an associate professor and from 2001 to 2004, a professor of Seikei University, Tokyo. Currently, he is a professor of Pure and Applied Physics Department, Graduate School of Advanced Science and Engineering, Waseda University .</p>
                <p>Dr. Morishima was having a sabbatical staying at Visual Modeling Group, Department of Computer Science, University of Toronto from 1994 to 1995 as a visiting professor . From 1999 to 2014, he was a temporary lecturer of Meiji University. From 1999 to 2010, he was an invited researcher of Advanced Telecommunication Research Laboratories from 2010 to 2014, an invited researcher of National Institute of Information and Communication Technologies . Currently, he is also a guest lecturer of Niigata University. He is engaging on a research project to produce a safe and secure society and innovative content and visual processing technology in Waseda Science and Engineering Research Institute .</p>
                <p>His research interests include Computer Graphics, Computer Vision, Multimodal Signal Processing and Human Computer Interaction. Dr. Morishima is a member of the IEEE, ACM SIGGRAPH, Senior member of Information Processing Society of Japan(IPSJ) and the Institute of Electronics, Information and Communication Engineers Japan(IEICE-J) . He is a trustee of Japanese Academy of Facial Studies. He is a fellow of the Information Processing Society Japan and Institute of Image Electronics Engineers of Japan. He was a former general chair of ACM VRST 2018 and got a Recognition of Service Award from ACM . He engaged in SIGGRAPH ASIA 2018 as VR/AR adviser, ACM VRST 2019 Program Chair and 2021 Sponsorship Chair. He is a general chair of Pacific Graphics 2022 and CG Kyoto General Chair.</p>
                <p> received the IEICE-J achievement award in May, 1992 and the Interaction 2001 best paper award from the Information Processing Society of Japan in Feb . 2001. He also received the Telecom System Technology Award from the Telecommunication Advancement Foundation in 2010. He has received a best paper award of Interaction 2019 from IPSJ . In 2020, he received CG Japan Award from the society of Art and Science and Hagura award (Forum Eight award) from State of the Art Technologies Expression Association.</p>
                <p>In 2021, he received IBM Academic Award with the title "Real-world Accessibility in New Era". </p>
            </div>
            <div v-else>
                <h2 class="text-center">森島繁生</h2>
                <p>1959年8月20日和歌山県西牟婁郡串本町生まれ. 1978年3月開成高等学校卒業．1978年4月東京大学理科一類入学．1982年東京大学工学部電子工学科卒業．1984年3月東京大学大学院工学系研究科電子工学専門課程修士課程．1987年3月同博士課程修了, 工学博士. 1987年4月成蹊大学工学部電気工学科専任講師, 1988年4月成蹊大学助教授, 2001年4月成蹊大学電気電子工学科教授, 2004年早稲田大学先進理工学部応用物理学科教授, 現在に至る .</p>
                <p>1994年から1995年 トロント大学コンピュータサイエンス学部客員教授, 1999年から2014年明治大学非常勤講師, 1999年より2010年国際電気通信基礎技術研究所客員研究員 . 2010年より2014年NICT招聘研究員, 現在, 新潟大学非常勤講師を併任．早稲田大学理工学研究所において安全、安心な社会を実現し豊かな文化を創造するコンテンツ・映像処理技術研究プロジェクトを推進．</p>
                <p>コンピュータグラフィックス, コンピュータビジョン, 音声情報処理, ヒューマンコンピュータインタラクション, 感性情報処理の研究に従事 . 1991年電子情報通信学会業績賞受賞, 2010年電気通信普及財団テレコムシステム技術賞受賞. 2019年情報処理学会インタラクション論文賞受賞, 2020年CG Japan Award受賞，2020年羽倉賞フォーラムエイト賞受賞．2021年IBM Academic Award受賞.</p>
                <p>情報処理学会フェロー，画像電子学会フェロー, 日本顔学会理事 . ACM VRST 2018 General Chair. SIGGRAPH ASIA 2015 Workshop/Partner Event Chair．SIGGRAPH ASIA 2018 VR/AR Advisor. ACM VRST 2019 Program Chair, 2021 Sponsorship Chair. Pacific Graphics 2022 General Chair, CG Kyoto General Chair. JST CREST Advisor. IEEE, ACM, 情報処理学会, 電子情報通信学会，映像情報メディア学会各会員 .</p>
            </div>
            <div class="row justify-content-center justify-content-md-end additionalURLs">
                <div class="col-auto">
                <a href="https://researchmap.jp/futurecast" target="_blank" rel="noopener noreferrer">
                    <img class="img-fluid" src="../../assets/researchmap.gif" width="220" height="66"/>
                </a>
                </div>
                <div class="col-auto">
                <a href="https://scholar.google.co.jp/citations?user=4B-C50EAAAAJ&hl=ja" target="_blank" rel="noopener noreferrer">
                    <img class="img-fluid" src="../../assets/google_scholar_logo.svg" width="66" height="66"/>
                    Google Scholar
                </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import firebase from 'firebase'

export default {
  name: 'Biography',
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style scoped>

.biography .anim-wrap
{
    position: relative;
    padding: 0.6rem;
}

.biography .trim-wrap
{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.biography .anim-wrap:before, .biography .anim-wrap:after 
{
    content: "";
    position: absolute;
    border-radius: 50%;
    transform: rotate(-90deg);
}

.biography .anim-wrap:before
{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-top: 1px solid #d4af37;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #d4af37;
    border-left: 1px solid transparent;
    z-index: 1;
}

.biography .anim-wrap:after 
{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 3px solid #e6e6e6;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #e6e6e6;
    border-left: 3px solid transparent;
    /* border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #fff;
    border-left: 3px solid transparent; */
    transition: transform 0.8s;
    z-index: 2;
}

.biography .anim-wrap:hover:after
{
    transform: rotate(0deg);
}

.biography .trim-wrap > img
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    width:auto;
    height:auto;
    max-width: initial;
    max-height:105%;
}

.biography .caption
{
    padding-right: 1rem;
    padding-left: 1rem;
    position: relative;
}

@media screen and (max-width: 767px) 
{
    .biography .caption
    {
        padding-top: 1rem;
    }
}

</style>