<template>
<div class="members">
  <div class="row justify-content-center mt-3">
    <div class="col-12 col-lg-8">
      <h2 class="position">{{ lang == 'en' ? 'Faculty' : '教員・スタッフ' }}</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Position</th>
            <th scope="col">Name</th>
            <th scope="col">Page URL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="researcher in this.researchers" :key="researcher.id">
            <td> {{ lang == 'en' ? researcher.status_en : researcher.status }} </td>
            <td> {{ lang == 'en' ? researcher.eng : researcher.name }}</td>
            <td v-if="researcher.url">[<a :href="lang == 'en' ? researcher.url+'?lang=en' : researcher.url" target="_blank" rel="noopener noreferrer">Page <i class="fas fa-external-link-alt"></i></a>]</td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-content-center mt-3">
    <div class="col-12 col-lg-8">
      <h2 class="position">{{ lang == 'en' ? 'Students' : '学生' }}</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Course</th>
            <th scope="col">Name</th>
            <th scope="col">Page URL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in this.students" :key="student.id">
            <td> {{ lang == 'en' ? student.status_en : student.status }} </td>
            <td> {{ lang == 'en' ? student.eng : student.name }}</td>
            <td v-if="student.url">[<a :href="student.url" target="_blank" rel="noopener noreferrer">Page <i class="fas fa-external-link-alt"></i></a>]</td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Members',
  data: function () {
    return {
      db: null,
      members: []
    }
  },
  created: function () {
    this.db = firebase.firestore()

    var _this = this
    this.db.collection('members').orderBy("index").onSnapshot(function (querySnapshot) {
      _this.members = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        _this.members.push(data)
      })
    })
  },
  computed: {
    researchers: function () {
      return this.members.filter(function (member) {
        return member.position==="教員・スタッフ"
      }
    )},
    students: function () {
      return this.members.filter(function (member) {
        return member.position!=="教員・スタッフ"
      }
    )},
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style>
@media screen and (max-width: 767px)
{
  .position
  {
    font-size: 1.5rem;
  }
}

.members .fa-external-link-alt
{
  font-size: 0.8em;
}
</style>