<template>
<div class="container my-4 my-lg-5">
  <h1>Contact Address</h1>
  <hr>
  <ContactInfo/>
  <h1>Collaborator</h1>
  <hr>
  <Collaborator/>
</div>
</template>

<script>
import ContactInfo from '@/components/contact/ContactInfo.vue'
import Collaborator from '@/components/contact/Collaborator.vue'


export default {
  name: 'Contact',
  data: function () {
    return {
      lang: this.$route.query.lang == 'en' ? 'en' : 'ja'
    }
  },
  components: {
    ContactInfo,
    Collaborator
  }
}
</script>