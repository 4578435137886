<template>
<div class="container my-4 my-lg-5 justify-content-center">
    <h1>Members</h1>
    <input type="file" @change="loadMembersCsvFile" />

    <h1>Projects</h1>
    <input type="file" @change="loadProjectsCsvFile" />

    <h1>Collaborators</h1>
    <input type="file" @change="loadCollaboratorsCsvFile" />

    <h1>Publications</h1>
    <input type="file" @change="loadPublicationsCsvFile" />

    <h1>Publications_web</h1>
    <input type="file" @change="loadPublicationsWebCsvFile" />

    <h1>News</h1>
    <input type="file" @change="loadNewsCsvFile" />

    <p>{{ message }}</p>

    <table border="1">
      <tr v-for="(worker, index) in workers" :key="index">
        <td v-for="(column, index) in worker" :key="index">{{ column }}</td>
      </tr>
    </table>
</div>
</template>
 
<script>
import firebase from 'firebase'
import 'firebase/firestore';

export default {
  data() {
    return {
      message: "",
      workers: [],
      db: null
    };
  },
  created: function() {
    this.db = firebase.firestore()
  },
  methods: {
    loadMembersCsvFile(e) {
      // メンバーのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // ログイン認証済
          console.log('auth user', user);
          let reader = new FileReader();
          reader.readAsText(file);
    
          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");
    
            // delete all members
            _this.db.collection("members").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("members").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // Write to members DB
                let member = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  member[keys[j].trimRight('\r')] = linesArr[i][j].trimRight('\r')
                }
                _this.db.collection('members').add(member)
              }
              vm.workers = linesArr;
            });       
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });

    },
    loadCollaboratorsCsvFile(e) {
      // メンバーのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");

            // delete all members
            _this.db.collection("collaborators").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("collaborators").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // Write to members DB
                let collaborator = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  collaborator[keys[j].trimRight('\r')] = linesArr[i][j].trimRight('\r')
                }
                _this.db.collection('collaborators').add(collaborator)
              }
              vm.workers = linesArr;
            });       
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });
    },
    loadPublicationsCsvFile(e) {
      // メンバーのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");

            // delete all members
            _this.db.collection("publications").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("publications").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // Write to members DB
                let publication = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  publication[keys[j].trimRight('\r')] = linesArr[i][j].trimRight('\r')
                }
                _this.db.collection('publications').add(publication)
              }
              vm.workers = linesArr;
            });       
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });
    },
    loadPublicationsWebCsvFile(e) {
      // メンバーのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");

            alert(lines);
            alert(keys);

            // delete all members
            _this.db.collection("publications").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("publications").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // Write to members DB
                let publication = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  publication[keys[j].trimRight('\r')] = linesArr[i][j].trimRight('\r')
                }
                _this.db.collection('publications').add(publication)
              }
              vm.workers = linesArr;
            });       
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });
    },
    loadProjectsCsvFile(e) {
      // プロジェクトのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");

            // delete all projects
            _this.db.collection("projects").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("projects").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // // Write to members DB
                let project = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  project[keys[j].trimRight('\r')] = linesArr[i][j].trimRight('\r')
                }
                _this.db.collection('projects').add(project)
              }
              vm.workers = linesArr;
            })
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });
    },
    loadNewsCsvFile(e) {
      // プロジェクトのデータを追加
      let vm = this;
      vm.workers = [];
      vm.message = "";
      let file = e.target.files[0];

      if (!file.name.substr(file.name.length - 3).match("tsv")) {
        vm.message = "TSVファイルを選択してください";
        return;
      }

      var _this = this
      // ログイン確認
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            var lines = reader.result.split("\n");
            var keys = lines[0].split("\t");

            // delete all projects
            _this.db.collection("news").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    _this.db.collection("news").doc(doc.id).delete();
                });
            }).then(function(){
              lines.shift();
              let linesArr = [];
              for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split("\t");
                
                // // Write to members DB
                let project = {};
                for (let j = 0; j < linesArr[i].length; j++){
                  let key = keys[j].trimRight('\r');
                  let value = linesArr[i][j].trimRight('\r');

                  if (key === 'year' || key === 'month' || key === 'day') {
                    project[key] = parseInt(value, 10); // convert to int if it is a date field
                  } else {
                    project[key] = value;
                  } 

                }
                _this.db.collection('news').add(project)
              }
              vm.workers = linesArr;
            })
          };
        } else {
          // No user is signed in.
          alert('please login');
          return;
        }
      });
    }
  }
};
</script>