<template>
<div class="container my-4 my-lg-5 justify-content-center">
<div class="col-12" v-if="login_status">
  <h4 >{{ login_status }}</h4>
  <h5 v-if="login_status"><a href='/signout'>signout</a></h5>
</div>


<table class="col-12" v-else>
    <tr>
      <th>メールアドレス：</th>
    </tr>
    <tr>
      <td><input type="email" v-model="mailaddress"/></td>
    </tr>
    <tr>
      <th>パスワード：</th>
    </tr>
    <tr>
      <td><input type="password" v-model="password"/></td>
    </tr>
    <button @click="login">ログイン</button>
  </table>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Signin',
  data: function () {
    return {
      login_status: ''
    }
  },
  created: function() {
    var _this = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        _this.login_status = 'ログイン中です。'
      }
    });
  },
  methods: {
    login: function () {
      firebase.auth().signInWithEmailAndPassword(this.mailaddress, this.password)
      .then(function() {
        alert('サインイン成功');
      }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert('サインイン失敗: ' + errorCode + ', ' + errorMessage);
      });
    }
  }
}
</script>
