<template>
<div class="container my-4 my-lg-5 justify-content-center">
    <h3>サインアウトしました！</h3>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Signout',
  created: function() {
    firebase.auth().signOut()
    .then(function() {
      console.log('サインアウト成功');
    }).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('サインアウト失敗: ' + errorCode + ', ' + errorMessage);
    });
  }
}
</script>
