<template>
<div class="container-fluid max-width my-4 my-lg-5">
  <h1>Publications</h1>
  <div class="view-option">
    <button type="button" :class="[ target == '論文誌' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('論文誌')">{{ lang == 'en' ? 'Journal Papers' : '論文誌' }}</button>
    <button type="button" :class="[ target == '国際会議論文' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('国際会議論文')">{{ lang == 'en' ? 'International conference' : '国際会議論文' }}</button>
    <button type="button" :class="[ target == '国内会議論文' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('国内会議論文')">{{ lang == 'en' ? 'Domestic conference' : '国内会議論文' }}</button>
    <button type="button" :class="[ target == 'シンポジウム・研究会' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('シンポジウム・研究会')">{{ lang == 'en' ? 'Symposium' : 'シンポジウム・研究会' }}</button>
    <button type="button" :class="[ target == '受賞' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('受賞')">{{ lang == 'en' ? 'Awards' : '受賞' }}</button>
    <button type="button" :class="[ target == '報道' ? 'btn btn-secondary' : 'btn btn-outline-secondary']" @click="updateTarget('報道')">{{ lang == 'en' ? 'Publications' : '報道' }}</button>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <List :target="target" />
    </div>
  </div>
</div>
</template>

<script>
import List from '@/components/publication/List.vue'

export default {
  name: 'Publications',
  data: function () {
    return {
      target: 'All'
    }
  },
  components: {
    List
  },
  methods: {
    updateTarget: function(new_target) {
      this.target = new_target
    }
  },
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style scoped>
.view-option > button
{
  margin: 5px;
}

.max-width
{
  max-width: 1600px;
}

@media (max-width: 575px)
{
  .max-width
  {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>